import { RecurlyGemPackItems, RecurlyGemsItemNames } from './RecurlyGemPackItems';
import { SubscriptionPlans } from './SubscriptionPlan';
import { environment } from '../config/environment';

const prefixPlan = environment?.RECURLY_PLAN_PREFIX;

export enum PaymentType {
    subscription = 'Arkadium Advantage',
    plus = 'Arkadium Plus',
    gems = 'Gems',
}

export type RecurlyPlanObject = {
    key: string;
    plan: string;
    title: string;
    description: string;
    price?: number;
    gemsAmount?: number;
};

const RecurlySubscriptionPlans = (plusFeatureFlag: boolean) => {
    const subscriptionPlans: any = {};

    Object.values(SubscriptionPlans).forEach((planName) => {
        subscriptionPlans[planName] = {};
        subscriptionPlans[planName].key = planName;
        subscriptionPlans[planName].plan = plusFeatureFlag
            ? `${prefixPlan}aasub2025_arkcom_${planName.toLowerCase()}`
            : `${prefixPlan}aasub_arkcom_${planName.toLowerCase()}`;
        subscriptionPlans[planName].title = plusFeatureFlag ? PaymentType.plus : PaymentType.subscription;
        subscriptionPlans[planName].description = `${planName} subscription`;
    });
    return subscriptionPlans;
};
const getRecurlyGoodsNames = (plusFeatureFlag: boolean) => {
    const subscriptionPlans = RecurlySubscriptionPlans(plusFeatureFlag);
    const gemPackItems = RecurlyGemPackItems(plusFeatureFlag);

    return {
        [PaymentType.subscription]: { ...subscriptionPlans },
        [PaymentType.gems]: { ...gemPackItems },
    };
};

export const RecurlyGoodsNames = {
    [PaymentType.subscription]: ['Annual', 'Monthly'],
    [PaymentType.gems]: RecurlyGemsItemNames,
};

export const RecurlyPlans = (plusFeatureFlag: boolean) => getRecurlyGoodsNames(plusFeatureFlag);

export function chooseGemsPacksPlan(isSubscriber: boolean, isTrialAvailable: boolean, plusFeatureFlag: boolean) {
    return RecurlyPlans(plusFeatureFlag)[PaymentType.gems][
        isSubscriber && !isTrialAvailable ? 'forSubscribers' : 'forRegistered'
    ];
}
